import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import "./tile-content-spacer.sass"

const TileContentSpacer = ({ size }) => {
  return (
    <div
      className={classNames(
        "_fp-tile__content-spacer",
        { "_fp-tile__content-spacer--large": size === "large" },
        { "_fp-tile__content-spacer--extra-small": size === "extra-small" },
        { "_fp-tile__content-spacer--small": size === "small" },
      )}
    ></div>
  )
}

TileContentSpacer.propTypes = {
  size: PropTypes.oneOf(["extra-small", "small", "large"]),
}

export default TileContentSpacer
