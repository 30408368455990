import PropTypes from "prop-types"
import React from "react"

import "./tile-container.sass"

const TileContainer = ({ children }) => {
  return <div className={"_fp-tile__container"}>{children}</div>
}

TileContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TileContainer
