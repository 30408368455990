import Hammer from "@egjs/hammerjs"
import React from "react"

class Slider extends React.Component {
  constructor(props) {
    super(props)

    this.hammerContainerRef = React.createRef()
    this.dragging = React.createRef()
  }

  componentDidMount() {
    if (this.hammerContainerRef.current) {
      var mc = new Hammer(this.hammerContainerRef.current, {
        recognizers: [
          // [
          //     Hammer.Pan,
          //     {event: 'panv', direction: Hammer.DIRECTION_VERTICAL}
          // ],
          [
            Hammer.Pan, // RecognizerClass
            { direction: Hammer.DIRECTION_HORIZONTAL, threshold: 10 }, // options
            // ['swipe'],                                // recognizeWith
            // ['panvertical']                           // requireFailure
          ],
        ],
      })

      mc.on("panleft panright", (e) => {
        this.handlePan(e)
      })

      mc.on("panend", (e) => {
        this.handlePanEnd(e)
      })

      mc.on("pancancel", (e) => {
        this.handlePanCancel(e)
      })
    }
  }

  handlePan(ev) {
    if (ev.isFinal) {
      return
    }

    if (!this.dragging.current) {
      this.dragging.current = true
      this.hammerContainerRef.current.classList.add("dragging")
    }

    this.props.onPan(ev.deltaX)
  }

  handlePanEnd(ev) {
    this.dragging.current = false
    this.hammerContainerRef.current.classList.remove("dragging")
    this.props.onPanEnd(ev.deltaX)
  }

  handlePanCancel(ev) {
    this.dragging.current = false
    this.hammerContainerRef.current.classList.remove("dragging")
    this.props.onPanEnd(ev.deltaX)
  }

  render() {
    return <div ref={this.hammerContainerRef}>{this.props.children}</div>
  }
}

export default Slider
