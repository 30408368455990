import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import { dynamicImageWithGatsbyImage } from "../../../util/dynamic-image"
import trackCustomEvent from "../../../util/trackCustomEvent"
import {
  getDynamicPdfFileUrl,
  getPdfFileItemForLanguage,
} from "../../dynamic-pdf-file/dynamic-pdf-file"

export const SustainabilityReport = (props) => {
  const { t } = useTranslation()
  const reportUrl = "/documents/MÜNZING Sustainability Report 2022.pdf"

  return (
    <div className="_fp-grid _fp-grid--gap">
      <div className="_fp-col-7 _fp-col-12sp _fp-col-12p _fp-col-12st _fp-global-regular-font-formatting">
        <p
          dangerouslySetInnerHTML={{ __html: t("SUSTAINABILITY-REPORT-TEXT") }}
        ></p>
        <a
          target={"_blank"}
          href={reportUrl}
          onClick={() =>
            trackCustomEvent({
              category: "sustainability_report",
              action: "sustainability_report_2022_download",
            })
          }
        >
          {t("SUSTAINABILITY-REPORT-LINK-TITLE")}
        </a>
      </div>
      <div className="_fp-col-5 _fp-col-12sp _fp-col-12p _fp-col-12st">
        <a
          target={"_blank"}
          href={reportUrl}
          onClick={() =>
            trackCustomEvent({
              category: "sustainability_report",
              action: "sustainability_report_2022_download",
            })
          }
          className={"shadow"}
          style={{ display: "block", maxWidth: "30rem" }}
        >
          <GatsbyImage
            image={dynamicImageWithGatsbyImage(props.image)}
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          ></GatsbyImage>
        </a>
      </div>
    </div>
  )
}
