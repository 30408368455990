import classNames from "classnames"
import React, { useRef, useState } from "react"
import { useMeasure, useWindowSize } from "react-use"

import Tile from "../tile/tile.js"
import HorizontalDragable from "./horizontal-dragable"
import "./icon-expansion-section.sass"

const IconExpansionSection = ({
  sections,
  titleTranslatedString,
  descriptionTranslatedHtml,
}) => {
  const containerRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const [ref, { width: itemWidth }] = useMeasure()
  const { width } = useWindowSize()

  return (
    <div
      className={"_fp-icon-expansion-section"}
      style={{ "--_fp-active-index": activeIndex }}
      ref={containerRef}
    >
      <Tile titleTranslatedString={titleTranslatedString}>
        <div
          className={"_fp-global-regular-font-formatting _fp-rich-text"}
          dangerouslySetInnerHTML={{ __html: descriptionTranslatedHtml }}
        ></div>

        <div
          className="_fp-icon-expansion-section__measure-item-width"
          ref={ref}
        ></div>
        <HorizontalDragable
          onPan={(deltaX) => {
            if (width >= 1200) {
              return
            }
            if (containerRef.current) {
              containerRef.current.style.setProperty("--_fp-pan", deltaX + "px")
            }
          }}
          onPanEnd={(deltaX) => {
            if (containerRef.current) {
              containerRef.current.style.setProperty("--_fp-pan", 0 + "px")
            }
            if (width >= 1200) {
              return
            }

            setActiveIndex(
              Math.min(
                sections.length - 1,
                Math.max(0, activeIndex + Math.round(-deltaX / itemWidth)),
              ),
            )
          }}
        >
          <div className="_fp-icon-expansion-section__icon-row">
            {sections.map((section, index) => {
              return (
                <div
                  className={classNames(
                    "_fp-icon-expansion-section__icon-row__icon",
                    {
                      "_fp-icon-expansion-section__icon-row__icon--active":
                        index === activeIndex,
                    },
                  )}
                  onClick={() => setActiveIndex(index)}
                  key={"icon-expansion-section-image-" + index}
                >
                  <img src={section.image} alt={section.title} />
                </div>
              )
            })}
          </div>
        </HorizontalDragable>
      </Tile>

      <div className="_fp-icon-expansion-section__active-indicator"></div>

      {sections.map((section, index) => {
        return (
          <div
            className={classNames("_fp-icon-expansion-section__item", {
              "_fp-icon-expansion-section__item--active": index === activeIndex,
            })}
            key={"icon-expansion-section-item-" + index}
          >
            <Tile titleTranslatedString={section.title}>
              <div
                className={"_fp-global-regular-font-formatting _fp-rich-text"}
                dangerouslySetInnerHTML={{ __html: section.description }}
              ></div>
            </Tile>
          </div>
        )
      })}
    </div>
  )
}

export default IconExpansionSection
