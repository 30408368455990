import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../i18n/translate"
import "./rich-text.sass"

const RichText = ({ textTranslationKey, ulCheckmarks, ...props }) => {
  const { t } = useTranslation()

  // we need to use a div here (see https://github.com/gatsbyjs/gatsby/issues/11108)
  return (
    <div
      {...props}
      className={classNames(
        "_fp-global-regular-font-formatting _fp-rich-text",
        { "_fp-global-regular-font-formatting--ul-checkmarks": ulCheckmarks },
      )}
      dangerouslySetInnerHTML={{ __html: t(textTranslationKey) }}
    />
  )
}

RichText.propTypes = {
  textTranslationKey: PropTypes.string.isRequired,
  ulCheckmarks: PropTypes.bool,
}

export default RichText
