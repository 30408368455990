import React from "react"

import RichText from "../rich-text/rich-text"
import "./text-with-image-hero.sass"

const PageHeader = ({
  titleTranslatedString,
  textTranslationKey,
  children,
}) => {
  return (
    <div className={"_fp-text-with-image-hero"}>
      <div className={"_fp-global-container-secondary"}>
        <div>
          <h2
            className={
              "_fp-heading-1 _fp-heading-1--large _fp-text-with-image-hero__title"
            }
          >
            {titleTranslatedString}
          </h2>
          <p className={"_fp-text _fp-text-with-image-hero__copy"}>
            <RichText textTranslationKey={textTranslationKey} />
          </p>
        </div>

        <div className="_fp-text-with-image-hero__image">{children}</div>
      </div>
    </div>
  )
}

export default PageHeader
