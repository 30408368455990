import classNames from "classnames"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import LocalizedLink from "../../i18n/localized-link"
import TileContainer from "./tile-container/tile-container"
import "./tile.sass"

class Tile extends React.Component {
  constructor(props) {
    super(props)

    // Create Link & Link Spacer Refs
    this.tileLinkRef = React.createRef()
    this.iconButtonElements = []
  }

  componentDidMount() {
    // Exceptions

    if (this.props.titleTranslatedString && this.props.titleComponent) {
      throw new Error(
        '"titleTranslatedString" & "titleComponent" props can`t both be set',
      )
    }
    if (this.props.iconButtons && this.props.titleComponent) {
      throw new Error(
        '"iconsButtons" and "titleComponent" props are incompatible, use "titleTranslatedString" instead',
      )
    }

    ///

    this.iconButtonElements = document.querySelectorAll(
      "._fp-tile__icon-buttons__icon-button",
    )
    this.headingTitleElement = document.querySelector(
      "._fp-tile__heading__title",
    )
  }

  render() {
    const {
      children,
      autoHeight,
      tileSize,
      maxContentWidth,
      titleTranslatedString,
      linkPath,
      linkTextTranslatedString,
      titleComponent,
      iconButtons,
      noTile,
    } = this.props

    return (
      <div
        className={classNames(
          "_fp-tile",
          { "_fp-tile--has-title": titleTranslatedString || titleComponent },
          { "_fp-tile--no-tile": noTile },
          { "_fp-tile--auto-height": autoHeight },
          { "_fp-tile--m": tileSize === "m" },
          { "_fp-tile--xs": tileSize === "xs" },
          { "_fp-tile--s": tileSize === "s" },
        )}
      >
        <TileContainer>
          {titleTranslatedString && (
            <div
              className={classNames("_fp-tile__heading", {
                "_fp-tile__heading--margin-bottom": !!children,
              })}
            >
              <h3
                className={classNames("_fp-heading-3 _fp-tile__heading__title")}
              >
                {titleTranslatedString}
              </h3>
              {iconButtons && iconButtons.length > 0 && (
                <div className="_fp-tile__icon-buttons">
                  {iconButtons.map((iconButton, index) => {
                    return (
                      <button
                        key={`icon_button_${index}`}
                        className={classNames(
                          "_fp-print-hidden",
                          "_fp-tile__icon-buttons__icon-button",
                          {
                            "_fp-tile__icon-buttons__icon-button--active":
                              iconButton.active,
                          },
                        )}
                        onClick={() => iconButton.onClick()}
                      >
                        <div className="_fp-tile__icon-buttons__icon-button__icon">
                          <iconButton.iconComponent />
                        </div>
                      </button>
                    )
                  })}
                </div>
              )}
            </div>
          )}
          {titleComponent}
          {children && (
            <div
              className={classNames("_fp-tile__content", {
                "_fp-tile__content--max-content": maxContentWidth,
              })}
            >
              {children}
            </div>
          )}
          {linkTextTranslatedString && linkPath && (
            <>
              <div ref={this.tileLinkRef} className="_fp-tile__link">
                <LocalizedLink
                  className={"_fp-cta-link _fp-cta-link--large"}
                  to={linkPath}
                >
                  {linkTextTranslatedString}
                </LocalizedLink>
              </div>
            </>
          )}
        </TileContainer>
      </div>
    )
  }
}

Tile.propTypes = {
  maxContentWidth: PropTypes.bool,
  noTile: PropTypes.bool,
  tileSize: PropTypes.oneOf(["m", "s", "xs"]),
  titleTranslatedString: PropTypes.string,
  titleComponent: PropTypes.node,
  linkPath: PropTypes.string,
  linkTextTranslatedString: PropTypes.string,
  autoHeight: PropTypes.bool,
  iconButtons: PropTypes.arrayOf(
    PropTypes.shape({
      iconComponent: PropTypes.node,
      active: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
}

Tile.defaultProps = {
  tileSize: "m",
  noTile: false,
  autoHeight: false,
  maxContentWidth: false,
}

export default Tile
